import { createStore } from 'vuex';
import * as auth from './modules/auth';
import * as tasks from './modules/tasks';
import * as profile from './modules/profile';
import * as settings from './modules/settings';
import * as projects from './modules/projects';
import * as ideas from './modules/ideas';
import * as models from './modules/models';
import * as studies from './modules/studies';
import * as weather from './modules/weather';
import * as books from './modules/books';
import * as activity from './modules/activity';

export default createStore({
  state: {
    isModal: false,
    modalName: ''
  },
  getters: {},
  mutations: {
    SET_MODAL (state, value) {
      state.isModal = value;
    },
    SET_STATE (state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {},
  modules: {
    auth,
    tasks,
    profile,
    projects,
    settings,
    ideas,
    models,
    studies,
    books,
    weather,
    activity,
  }
});
