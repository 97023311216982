export const task = {
  types: [
    'meeting',
    'office',
    'invoices',
    'coding',
    'review',
    'web',
    'payment',
    'brainstorm'
  ],
  time: ['min.', 'hours']
};

export const reminder = {
  types: ['buy', 'invoice', 'sync', 'call', 'verify', 'pay']
};
export const tableHeader = [
  { name: '', prop: 'index' },
  { name: 'task', prop: 'title' },
  { name: 'date', prop: 'date' },
  { name: 'type', prop: 'type' },
  { name: 'energy', prop: 'energy' },
  { name: 'duration', prop: 'time' },
  { name: 'ddl.', prop: 'ddl' },
  { name: 'time', prop: 'start' },
  { name: 'appetite', prop: 'appetite' }
];
export const taskDetails = [
  { label: 'energy', prop: 'energy', unit: '%' },
  { label: 'time', prop: 'time' },
  { label: 'type', prop: 'type' },
  { label: 'ddl.', prop: 'ddl' },
  { label: 'appetite', prop: 'appetite' },
  { label: 'notes', prop: 'notes' }
];
export const sectionsList = [
  { name: 'Daily', prop: 'daily' },
  { name: 'All', prop: 'all' },
  { name: 'Done', prop: 'done' },
  { name: 'Report' }
];
export const days = [
  { name: 'Luni', number: 1 },
  { name: 'Marti', number: 2 },
  { name: 'Miercuri', number: 3 },
  { name: 'Joi', number: 4 },
  { name: 'Vineri', number: 5 },
  { name: 'Sambata', number: 6 },
  { name: 'Duminica', number: 0 }
];

export const projectMenuTabs = [
  { name: 'Description', key: 'details' },
  { name: 'Projects', key: 'activities' },
  { name: 'Strategy', key: 'strategy' },
  { name: 'Planning', key: 'planning' },
  { name: 'Tasks', key: 'tasks' },
  { name: 'Decisions', key: 'decisions' },
  { name: 'Meetings', key: 'meetings' },
  { name: 'Notes', key: 'notes' },
  { name: 'Participants', key: 'participants' },
  { name: 'MVP', key: 'mvp' },
  { name: 'CRMs', key: 'crms' },
  { name: 'Tech', key: 'tech' },
  { name: 'Docs', key: 'docs' },
  { name: 'Links', key: 'links' },
  { name: 'Settings', key: 'settings' }
];

export const invalidLogin = 'Username or password invalid.';

export const validationMsgs = {
  required: 'This field is mandatory.'
};

export const reqStates = {
  fetching: 'se comunica cu serverul.',
  saved: 'datele au fost salvate cu succes.'
}

export function getDateForDayOfWeek (dayOfWeek) {
  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const difference = dayOfWeek - currentDayOfWeek;
  const targetDate = new Date(today);
  targetDate.setDate(today.getDate() + difference);

  // Format the date as 'month/day/year'
  return `${
    targetDate.getMonth() + 1
  }/${targetDate.getDate()}/${targetDate.getFullYear()}`;
}

export function getDaysInCurrentMonth () {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
  return lastDayOfMonth.getDate();
}

export function formDataToObject (formData) {
  const object = {};
  formData.forEach((value, key) => {
    // Check if the key already exists in the object
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      // If the key already exists, convert the value to an array
      // This is useful if the form contains multiple values for the same key
      if (!Array.isArray(object[key])) {
        object[key] = [object[key]];
      }
      object[key].push(value);
    } else {
      // If the key doesn't exist, set the value directly
      object[key] = value;
    }
  });
  return object;
}